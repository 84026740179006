<template>
    <Dialog ref="dialog" :config="config" :visible='visible' title="同步数据" :cancelDialog="cancelDialogs" :confirmDialog='syncSubmit' :isdeling='isdeling'>
        <el-form ref="mainForm" :rules="rules" :model="tmpData" label-width="8rem" class="form1">
            <el-form-item label='发票代码' prop="fpdm">
              <el-input v-model='tmpData.fpdm' maxlength="50" placeholder="请输入发票代码"/>
            </el-form-item>
            <el-form-item label='起始发票号码' prop='fphmStart'>
              <el-input v-model='tmpData.fphmStart' maxlength="50" placeholder="请输入起始发票号码"/>
            </el-form-item>
            <el-form-item label='截至发票号码' prop='fphmEnd'>
              <el-input v-model='tmpData.fphmEnd' maxlength="50" placeholder="请输入截至发票号码"/>
            </el-form-item>
            <el-form-item label='开票日期' prop='kprq'>
              <el-date-picker
                v-model="tmpData.kprq"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请选择开票日期">
              </el-date-picker>
            </el-form-item>
      </el-form>
    </Dialog>
</template>
<script>
import Dialog from "@/components/Dialog";
export default {
    props: {
      visible: {
        type: Boolean,
      },
      // title : {
      //   type: String,
      // },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      confirmDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {
      Dialog,
    },
    data() {
        return {
            config: {
                top: '10vh',
                width: '46rem',
                title: null,
                center: true,
                btnTxt: ['同步', '取消'],
            },
            isdeling: false,
            tmpData: {
                fphmStart:"",
                fphmEnd:"",
                fpdm:"",
                kprq:"",
            },
            rules: {
                fphmStart: [
                    { required: true, message: "请输入起始发票号码" },
                    // { min: 1, max: 20, message: "发票号码长度在 1 到 20 个字符", trigger: 'blur' },
                    // {pattern: "^[^ ]+$",message:'发票号码不能含空格'}
                ],
                fphmEnd: [
                    { required: true, message: "请输入起始发票号码" },
                ],
                fpdm: [
                    { required: true, message: "请输入发票代码" },
                ],
                kprq: [
                    { required: true, message: "请选择开票日期" },
                ],
            },
        }
    },
    methods:{
        cancelDialogs(){
            this.cancelDialog();
        },
        syncSubmit(){
            // 同步税控信息
            console.log('提交数据')
            this.$refs.mainForm.validate((valid) => {
                if (valid) {
                    this.confirmDialog(this.tmpData.fpdm,this.tmpData.fphmStart,this.tmpData.fphmEnd,this.tmpData.kprq);
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>

</style>