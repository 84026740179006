<template>
  <!-- <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialogs" :confirmDialog='importConfirm' :isdeling='isdeling'> -->
  <msg :config="config" :type='type' :promptVisible="visible" class="employ_msg" :title="title" :cancelDialog="cancelDialogs" :confirmDialog='tipsConfirm' :isdeling='isDeling' ref="msg">
    <!-- 写表单验证 start -->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm" style="width: 100%">
      <p class="label"><i style="color:red">*</i> 授权码</p>
      <el-form-item label="" prop="permissionsNum" class="item">
        <el-input v-model="ruleForm.permissionsNum" :disabled="disabled" placeholder="请输入授权码"></el-input>
      </el-form-item>
      <!-- <div class="tips">
        <p>注意：</p>
        <p>1.取票申请时间=T+1(T:代表开票时间)</p>
        <p>2.取票结果查询时间=T+2(T:代表开票时间)</p>
        <p>3.每次默认拉取最新发票数据。</p>
        <p>4.当前公司本月发票拉取次数还剩 <span>5</span> 次,请谨慎</p>
      </div> -->
      <p class="label"><i style="color:red">*</i> 取票时间</p>
      <el-row style="width: 100%">
        <el-form-item label="" required>
          <el-col :span="11">
            <el-form-item prop="startDate" style="width:100%" >
              <el-date-picker
                  v-model="ruleForm.startDate"
                  placeholder="开始日期"
                  :picker-options="startDatePicker"
                  style="width: 100%"
                  format="yyyy年MM月dd日"
                  value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
           <el-col :span="2" class="zhi">至 </el-col>
          <el-col :span="11">
            <el-form-item prop="endDate" style="width:100%">
              <el-date-picker
                  v-model="ruleForm.endDate"  
                  placeholder="结束日期"
                  :picker-options="endDatePicker"
                  style="width: 100%"
                  format="yyyy年MM月dd日"
                  value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-row>
    </el-form>
    <!-- 写表单验证 end -->
  </msg>
</template>

<script>
  import { checkPermission, getInvoice, } from '@/api/getInvoice'
import log from '../../store/modules/recycleBin';
  // import axios from 'axios';
  export default {
    props: {
      visible: {
        type: Boolean,
      },
      isdeling : {
        type: Boolean,
        default: false,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      // confirmDialog: {
      //   type: Function,
      //   default: () => { },
      // },
      subjectType:{
        type:String,
      }
    },
    components: {
    },
    data() {
      // let validatePerm = (rule, value, callback) => {
      //   if (!value) {
      //     return callback(new Error('请先维护取票授权码，完成授权'));
      //   } else if (!/^[a-zA-Z0-9]{6}$/.test(value)) {
      //     return callback(new Error('请维护有效授权码')); 
      //   }
      // };
      return {
        config: {
          top: '25vh',
          width: '26.6rem',
          center: true,
          btnTxt: ['确认','取消'],
        },
        // type:'success',
        type:'warning',
        title:'智能取票',
        // type:'error',
        downloadType: '1',
        // 表单信息
        ruleForm: {
          permissionsNum: '',
          startDate:'',
          endDate:'',
        },
        // 校验规则
        rules: {
          permissionsNum: [
            // { validator: validatePerm, trigger: 'blur' },
            { required: true, message: ' 请输入您的授权码', trigger: 'blur' },
            // { min: 6, max: 6, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          // startDate:[{type: 'date', required: true, message: '请选择日期', trigger: 'blur'}],
          startDate:[{ required: true, message: '请选择日期', trigger: 'blur'}],
          endDate:[{ required: true, message: '请选择日期', trigger: 'blur'}],
        },
        startDatePicker: this.beginDate(),
        endDatePicker: this.processDate(),
        disabled:false,
        isDeling:false

        // companyId:this.$store.state.eInv.companyId,
        // userName:this.$store.state.user.userName
      };
    },
    methods: {
      cancelDialogs(){
        this.cancelDialog();
        console.log("点击了关闭按钮，进行关闭")
      },
      //传给弹窗的 '确认'处理函数
      tipsConfirm(){
        this.getInvoiceHandle()
        console.log("点击了弹窗的确认按钮")

      },
      // 开始时间
      beginDate(){
        const self = this
        return {
          disabledDate(time){
            if (self.ruleForm.endDate) {
              //如果结束时间不为空，则小于结束时间
              //时区转换
              var ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              var stime = new Date(self.ruleForm.endDate)
              stime.setFullYear(stime.getFullYear()-1)
              stime.setDate(stime.getDate()+1)

              // return new Date(self.ruleForm.endDate).getTime() < ntime.getTime() ||ntime.getTime()<stime.getTime()
               return new Date(self.ruleForm.endDate).getTime() ;
            }
            return false
          }
        }
      },
      //结束时间
      processDate() {
        const  self = this
        return {
          disabledDate(time) {
            if (self.ruleForm.startDate) {  //如果开始时间不为空，则结束时间大于开始时间
              //时区转
              var ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              var etime = new Date(self.ruleForm.startDate)
              etime.setFullYear(etime.getFullYear()+1)
              etime.setDate(etime.getDate()-1)
             // return new Date(self.ruleForm.startDate).getTime() > ntime.getTime() ||ntime.getTime()>etime.getTime()
              return new Date(self.ruleForm.startDate).getTime() > ntime.getTime()
            }
          }
        }
      },
      //校验是否有授权码
      checkPermissionNum(){
        // console.log('11111111111');
        checkPermission({
          companyId: this.getToken('companyId'),
          username: this.getToken('userName'),
        }).then((res)=>{
          console.log(res,'授权码校验')
          if(res.data.authorizationCode){
            this.disabled= true
            this.ruleForm.permissionsNum= res.data.authorizationCode
            console.log(this.ruleForm.permissionsNum)
          } 
        }).catch((err)=>{
            console.log(err,'授权码失败')  
        })
      },
      // 确认-智能取票
      getInvoiceHandle(){
        this.isDeling = true;
        console.log('点击确认智能取票')
          this.$refs.ruleForm.validate((valid) => {
            if (valid) {
              getInvoice({
                endDate:this.ruleForm.endDate,
                startDate:this.ruleForm.startDate,
                subjectType:this.subjectType,
                companyId: this.getToken('companyId'),
                username: this.getToken('phone'),
                userId: this.getToken('userId'),
                authorizationCode:this.ruleForm.permissionsNum,
                agencyCompanyId:this.getToken('agencyCompanyId'),
              }).then((res)=>{
                console.log('22222222',res);
                this.cancelDialog();
                if(res.success){
                  einvAlert.success("提示",'拉取成功')
                }else {
                    einvAlert.error("拉取失败",res.message||res.msg)
                }
                this.isDeling = false;
              }).catch((err)=>{
                  err ? einvAlert.error("拉取失败",err.msg||err.message||err) : einvAlert.error("拉取失败",'未知异常')
                  this.isDeling = false; 
              })
              
            } else {
              this.isDeling = false;
              einvAlert.error("提示",'请输入完整信息')
              return false;
            }
          })

      },
    //   getInvoiceHandle(){
    //     axios({
    //       method:'post',
    //       url:'api/einvoice/einv​/smartCollectInvoice',
    //       responseType: 'blob',
    //       data:{
    //         endDate:this.endDate,
    //         startDate:this.startDate,
    //         subjectType:'2',
    //         companyId: this.getToken('companyId'),
    //         username: this.getToken('userName'),
    //       },
    //       headers: {
    //         'Content-Type': "application/json;charset=utf-8",
    //         'Authorization': this.getToken('Authorization'),
    //       }
    //     }).then((res) =>{
    //       console.log(res,'校验日期')
    //     }).catch((err)=>{
    //       console.log(err,'校验日期失败')
    //     })
    //   }
    },
    created(){

    },
    watch:{
          idDeling: {
        handler (newName, oldName) {
            this.isDeling = this.isdeling
        },
        immediate: true
    }
    },
    computed: {

    }
  };
</script>

<style lang="less" scoped>
  // .download_tip {
  //   text-align: center;
  //   font-size: 16px;
  //   margin-bottom: 30px;
  // }
  // .download_select {
  //   width: 100%;
  //   /deep/ label {
  //     width: 100%;
  //     padding-left: 100px;
  //     margin-bottom: 20px;
  //     font-size: 16px !important;
  //   }
  // }
  // .employ_msg {
  //   /deep/.el-form-item__label {
  //     font-size: 17px !important;
  //     text-align: left;
  //   }
  // }
  .label, .zhi {
    font-size: 16px !important;
  }
  .label {
    margin-bottom: .5rem;
  }
  /deep/.el-input__inner {
    font-size: 15px;
  }
  .tips{
    p{
      margin: 0;
      line-height: 30px;
      font-size: 16px;
    }
    span{
      color: #F32F2F;
    }
  }

</style>
